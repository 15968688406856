import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import axios from 'axios'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Bounce, ToastContainer, toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import HeaderPages from '../components/header/HeaderPages'
import {Footer} from '../components/Footer/Footer'
import {store} from '../reducers'
import 'moment-hijri';
import DatePicker from "react-multi-date-picker"
import arabic from "react-date-object/calendars/arabic"
import gregorian from "react-date-object/calendars/gregorian"
import arabic_ar from "react-date-object/locales/arabic_ar"
import gregorian_ar from "react-date-object/locales/gregorian_ar"

const OrganizationReg = () => {
  const {t} = useTranslation()

  const navigate = useNavigate()
  const [options, setOptionsnData] = useState<any>()
  const [success, setSuccess] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [value, setValue] = useState(new Date())


  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateFormat, setDateFormat] = useState('gregorian');

  const userDate = store.getState().authStoreState.token
  const lang = localStorage.getItem('site_lang') || 'ar'

  const [activeTab, setActiveTab] = useState('tab1')

  const changeTab = (tab: any) => {
    setActiveTab(tab)
  }


  function handleChange(values: any){
    //your modification on passed value ....
    const dateValue = values?.day + '/' + values?.month + '/' + values?.year || values
    setValue(dateValue)
  }

  const handleDateFormatChange = (e: any) => {
    setDateFormat(e.target.value);
  };

  const getAuthHeaderLang = () => ({
    Authorization: `Bearer ${userDate}`,
    'Accept-Language': lang,
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'content-type': 'multipart/form-data',
  })

  const getAuthHeader = () => ({
    'Accept-Language': lang,
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'content-type': 'multipart/form-data',
  })

  useEffect(() => {
    axios
      .get('https://dev.thecontentapp.net/api/media/organizationOptions', {
        headers: getAuthHeaderLang(),
      })
      .then((response) => {
        setOptionsnData(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const regex = new RegExp(
    /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/
  )

  const exceptThisSymbols = ['e', 'E', '+', '-', '.']

  const SignupSchema = Yup.object().shape({
    organization_name: Yup.string().required('الرجاء اضافة اسم المنظمة'),
    email: Yup.string().email(t('emailerror')).required(t('emailerror')),
    password: Yup.string()
      .required('الرجاء اضافة الرقم السري')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    phone: Yup.string()
      .matches(regex, 'الرجاء ادخال رقم صحيح')
      .required('الرجاء اضافة رقم الجوال'),
    license_number: Yup.string().required('الرجاء اضافة رقم الترخيص'),
    organization_type_id: Yup.string().required('الرجاء اختيار نوع المنظمة'),
    license_date: Yup.string().required('الرجاء اضافة تاريخ الترخيص'),
    location: Yup.string().required('الرجاء اضافة المقر'),
    website: Yup.string().required('الرجاء اضافة الموقع الالكترونى'),
    commissioner_name: Yup.string().required('الرجاء اضافة اسم المفوض'),
    tax_number: Yup.string().required('الرجاء اضافة رقم التسجيل الضريبي'),
    commissioner_attribute_id: Yup.string().required('الرجاء اضافة صفة المفوض'),
    authorization_letter: Yup.mixed()
      .required('الرجاء رفع خطاب التفويض')
      .test('fileFormat', t('commissionerlattermsg'), (value: any) => {
        if (value) {
          const supportedFormats = ['pdf']
          return supportedFormats.includes(value.name.split('.').pop())
        }
        return true
      })
      .test('fileSize', t('commissionerlattermsg'), (value: any) => {
        if (value) {
          return value.size <= 3145728
        }
        return true
      }),
    // agreement_letter: Yup.mixed()
    //   .required('الرجاء رفع خطاب الموافقة')
    //   .test('fileFormat', t('commissionerlatteraccmsg'), (value: any) => {
    //     if (value) {
    //       const supportedFormats = ['pdf']
    //       return supportedFormats.includes(value.name.split('.').pop())
    //     }
    //     return true
    //   })
    //   .test('fileSize', t('commissionerlatteraccmsg'), (value: any) => {
    //     if (value) {
    //       return value.size <= 3145728
    //     }
    //     return true
    //   }),
    // id_number: Yup.string()
    //   .required('الرجاء اضافة رقم الهوية الوطنية')
    //   .min(2, 'الرقم قصير جداً')
    //   .max(10, 'الرقم طويل جداً'),
    // checkbox: Yup.bool().oneOf([true], 'الرجاء الموافقة علي الشروط و الاحكام'),
  })

  const Register = useFormik({
    initialValues: {
      organization_name: '',
      email: '',
      phone: '',
      license_number: '',
      password: '',
      organization_type: '',
      license_date: '',
      location: '',
      website: '',
      commissioner_name: '',
      commissioner_attribute_id: '',
      tax_number: '',
      authorization_letter: '',
      // agreement_letter: '',
      id_number: '',
      checkbox: false,
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      setSuccess(true)
      axios
        .post('https://api.sm.org.sa/api/media/auth/register', values, {
          headers: getAuthHeader(),
        })
        .then((response: any) => {
          if (response.status === true) {
            setSuccess(false)
            toast.success(
              'شكراً لتسجيلك في المنصة الإعلامية الرقمية للقطاع غير الربحي! قريباً ستتلقي إشعاراً بالبريد الإلكتروني فور توثيق الحساب.',
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
              }
            )
            setTimeout(() => {
              navigate('/')
            }, 5000)
          } else {
            setSuccess(false)
            toast.error(response.data, {
              position: "top-center",
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  })

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="flex flex-col">
        <div className="space-y-12">
          <div className="rounded-xl border-gray-900/10 bg-slate-100 p-5 pb-12 lg:p-16">
            <h2 className="mb-8 text-center text-xl font-semibold leading-7 text-gray-900">
              {t('registerNew')}
            </h2>
            {/* <p className="text-center py-10 lg:py-28 font-bold">نأسف، تم استكمال العدد المستهدف من المنظمات في المرحلة الأولى</p> */}
            <form onSubmit={Register.handleSubmit}>
              <div className="media-form-block">
                <h2 className="mt-6 px-4 text-start text-xl font-semibold leading-7 text-gray-900">
                  {t('registerInfo')}
                </h2>
                <div className="flex flex-wrap items-start justify-between">
                  <div className="flex-2 w-100 lg:w-50 items-center lg:flex-1">
                    <div className="px-4">
                      <div className="m-auto my-8">
                        <label
                          htmlFor="organization_type_id"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('typeorganization')}
                        </label>
                        <select
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          name="organization_type_id"
                          id="organization_type_id"
                          onChange={Register.handleChange}
                          onBlur={Register.handleBlur}
                          value={Register.values.organization_type_id}
                        >
                          <option value="0">{t('typeorganization')}</option>
                          {options?.organization_types &&
                          options?.organization_types.map(
                            (item: any, index: number) => (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            )
                          )}
                        </select>
                        {Register.errors.organization_type_id &&
                          Register.touched.organization_type_id && (
                            <div className="text-sm text-red-600">
                              {Register.errors.organization_type_id}
                            </div>
                          )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="license_number"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('organizationnumber')}
                        </label>
                        <input
                          type="text"
                          name="license_number"
                          id="license_number"
                          onChange={(e: any) => {
                            Register.setFieldValue(
                              'license_number',
                              e.target.value.replace(/[^0-9]/g, '')
                            )
                          }}
                          onBlur={Register.handleBlur}
                          value={Register.values.license_number}
                          placeholder={t('organizationnumber')}
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.license_number &&
                          Register.touched.license_number && (
                            <div className="text-sm text-red-600">
                              {Register.errors.license_number}
                            </div>
                          )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="location"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('organizatiolocation')}
                        </label>
                        <input
                          type="location"
                          name="location"
                          id="location"
                          onChange={Register.handleChange}
                          onBlur={Register.handleBlur}
                          value={Register.values.location}
                          placeholder={t('organizatiolocation')}
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.location &&
                          Register.touched.location && (
                            <div className="text-sm text-red-600">
                              {Register.errors.location}
                            </div>
                          )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="website"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('organizatiowebsite')}
                        </label>
                        <input
                          type="website"
                          name="website"
                          id="website"
                          onChange={Register.handleChange}
                          onBlur={Register.handleBlur}
                          value={Register.values.website}
                          placeholder={t('organizatiowebsite')}
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.website &&
                          Register.touched.website && (
                            <div className="text-sm text-red-600">
                              {Register.errors.website}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="flex-2 w-100 lg:w-50 items-center lg:flex-1">
                    <div className="px-4">
                      <div className="m-auto my-8">
                        <label
                          htmlFor="organization_name"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('organizationName')}
                        </label>
                        <input
                          type="organization_name"
                          name="organization_name"
                          id="organization_name"
                          onChange={Register.handleChange}
                          onBlur={Register.handleBlur}
                          value={Register.values.organization_name}
                          placeholder={t('organizationName')}
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.organization_name &&
                          Register.touched.organization_name && (
                            <div className="text-sm text-red-600">
                              {Register.errors.organization_name}
                            </div>
                          )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="license_date"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('organizationdate')}
                        </label>
                        <div className="flex">
                          <select value={dateFormat} onChange={handleDateFormatChange} className='mb-3 block w-1/4 me-4 rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'>
                            <option value="gregorian">{t('Gregorian')}</option>
                            <option value="hijri">{t('Hijri')}</option>
                          </select>
                          <DatePicker
                            calendar={dateFormat === 'gregorian' ? gregorian : arabic}
                            locale={dateFormat === 'gregorian' ? gregorian_ar : arabic_ar}
                            onChange={(date: any) => {
                              const dateValue = date?.year + '-' + date?.month + '-' + date?.day || date
                              setValue(dateValue)
                              setStartDate(dateValue)
                              Register.setFieldValue('license_date', dateValue)
                            }}
                            value={startDate}
                            maxDate={new Date()}
                            inputClass="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                        {Register.errors.license_date &&
                          Register.touched.license_date && (
                            <div className="text-sm text-red-600">
                              {Register.errors.license_date}
                            </div>
                          )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="association_name"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('vatNumber')}
                        </label>
                        <input
                          type="tax_number"
                          name="tax_number"
                          id="tax_number"
                          onChange={(e: any) => {
                            Register.setFieldValue(
                              'tax_number',
                              e.target.value.replace(/[^0-9]/g, '')
                            )
                          }}
                          onBlur={Register.handleBlur}
                          value={Register.values.tax_number}
                          placeholder={t('vatNumber')}
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.tax_number &&
                          Register.touched.tax_number && (
                            <div className="text-sm text-red-600">
                              {Register.errors.tax_number}
                            </div>
                          )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="email"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('organizatioemail')}
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          onChange={Register.handleChange}
                          onBlur={Register.handleBlur}
                          value={Register.values.email}
                          placeholder={t('organizatioemail')}
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.email && Register.touched.email && (
                          <div className="text-sm text-red-600">
                            {Register.errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="media-form-block">
                <h2 className="mt-6 px-4 text-start text-xl font-semibold leading-7 text-gray-900">
                  {t('commissionerinfo')}
                </h2>
                <div className="flex flex-wrap items-start justify-between">
                  <div className="flex-2 w-100 lg:w-50 items-center lg:flex-1">
                    <div className="px-4">
                      <div className="m-auto my-8">
                        <label
                          htmlFor="phone"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('commissionername')}
                        </label>
                        <input
                          type="commissioner_name"
                          name="commissioner_name"
                          id="commissioner_name"
                          onChange={Register.handleChange}
                          onBlur={Register.handleBlur}
                          value={Register.values.commissioner_name}
                          placeholder={t('commissionername')}
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.commissioner_name &&
                          Register.touched.commissioner_name && (
                            <div className="text-sm text-red-600">
                              {Register.errors.commissioner_name}
                            </div>
                          )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="authorization_letter"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('commissionerlatter')}
                        </label>
                        <label className="mb-3 block w-full rounded-md border-0 bg-white px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <input
                            type="file"
                            name="authorization_letter"
                            onChange={(e: any) => {
                              Register.setFieldValue(
                                'authorization_letter',
                                e.target.files[0]
                              )
                            }}
                            id="authorization_letter"
                            accept="application/pdf"
                          />
                        </label>
                        {Register.errors.authorization_letter &&
                          Register.touched.authorization_letter && (
                            <div className="text-sm text-red-600">
                              {Register.errors.authorization_letter}
                            </div>
                          )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="phone"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('phonenumber')}
                        </label>
                        <input
                          type="phone"
                          name="phone"
                          id="phone"
                          maxLength={10}
                          onChange={(e: any) => {
                            Register.setFieldValue(
                              'phone',
                              e.target.value.replace(/[^0-9]/g, '')
                            )
                          }}
                          onBlur={Register.handleBlur}
                          value={Register.values.phone}
                          placeholder="05xxxxxxxx"
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.phone && Register.touched.phone && (
                          <div className="text-sm text-red-600">
                            {Register.errors.phone}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex-2 w-100 lg:w-50 items-center lg:flex-1">
                    <div className="px-4">
                      <div className="m-auto my-8">
                        <label
                          htmlFor="commissioner_attribute_id"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('commissionerlattelabel')}
                        </label>
                        <select
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          name="commissioner_attribute_id"
                          id="commissioner_attribute_id"
                          onChange={Register.handleChange}
                          onBlur={Register.handleBlur}
                          value={Register.values.commissioner_attribute_id}
                        >
                          <option value="0">{t('commissionerlattelabel')}</option>
                          {options?.commissioner_attributes &&
                            options?.commissioner_attributes.map(
                              (item: any, index: number) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              )
                            )}
                        </select>
                        {Register.errors.commissioner_attribute_id &&
                          Register.touched.commissioner_attribute_id && (
                            <div className="text-sm text-red-600">
                              {Register.errors.commissioner_attribute_id}
                            </div>
                          )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="id_number"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('commissionercard')}
                        </label>
                        <input
                          type="text"
                          name="id_number"
                          id="id_number"
                          onChange={(e: any) => {
                            Register.setFieldValue(
                              'id_number',
                              e.target.value.replace(/[^0-9]/g, '')
                            )
                          }}
                          onBlur={Register.handleBlur}
                          value={Register.values.id_number}
                          maxLength={10}
                          placeholder={t('commissionercard')}
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.id_number &&
                          Register.touched.id_number && (
                            <div className="text-sm text-red-600">
                              {Register.errors.id_number}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="media-form-block">
                <h2 className="mt-6 px-4 text-start text-xl font-semibold leading-7 text-gray-900">
                  {t('accountinfo')}
                </h2>
                <div className="flex flex-wrap items-start justify-between">
                  <div className="flex-2 w-100 lg:w-50 items-center lg:flex-1">
                    <div className="px-4">
                      <div className="m-auto my-8">
                        <label
                          htmlFor="username"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('username')}
                        </label>
                        <input
                          type="text"
                          name="username"
                          id="username"
                          onChange={Register.handleChange}
                          onBlur={Register.handleBlur}
                          value={Register.values.email}
                          disabled
                          placeholder={t('username')}
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.email && Register.touched.email && (
                          <div className="text-sm text-red-600">
                            {Register.errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex-2 w-100 lg:w-50 items-center lg:flex-1">
                    <div className="px-4">
                      <div className="m-auto my-8">
                        <label
                          htmlFor="phone"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('password')}
                        </label>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          onChange={Register.handleChange}
                          onBlur={Register.handleBlur}
                          value={Register.values.password}
                          placeholder="*****************"
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.password &&
                          Register.touched.password && (
                            <div className="text-sm text-red-600">
                              {Register.errors.password}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="media-form-block mb-20">
                <div className="flex flex-wrap items-center px-4">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="checkbox"
                      onChange={(e: any) => {
                        Register.setFieldValue(
                          'checkbox',
                          !Register.values.checkbox
                        )
                      }}
                      className="me-4 h-4 w-4 text-xs md:text-sm"
                    />
                    <p>
                      {t('acceptprivacypolicy')}
                      <Link
                        to={'/terms'}
                        target="_blank"
                        className="hover:text-primary_color text-sm font-semibold text-black underline"
                      >
                        {t('terms')}
                      </Link>
                    </p>
                  </label>
                </div>
              </div>
              <div className="px-4"></div>
              <div className="item-center col-span-full m-auto">
                <button
                  type="submit"
                  className={
                    Register.values.checkbox === false
                      ? 'bg-primary_color flex rounded-md bg-indigo-600 px-24 py-5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-75'
                      : 'bg-primary_color flex rounded-md bg-indigo-600 px-24 py-5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  }
                  disabled={!Register.values.checkbox}
                >
                  {t('registerorganization')}
                  {success && <div className="loader"></div>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  )
}

export default OrganizationReg
